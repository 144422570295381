import React   from 'react'




const Masurment_unit = (props) => {




    // const changeWidth = (imgWidth,imgHeight) => {
    //     var yourImg = document.getElementById('step1');
    //     if(yourImg && yourImg.style) {
    //         yourImg.style.height = imgHeight + 'px';
    //         yourImg.style.width = imgWidth + 'px';
    //     }

    let main_height;
    let actual_height;

    if (window.innerWidth < 600) {
        if(props.switchoption == "Snowboard")
          {
            main_height = 250;
          }
          else
          {
            main_height = 250;
          }

      actual_height = main_height * props.size / 170
    }
    else if(window.innerWidth < 1799)
    {
      if(props.switchoption == "Snowboard")
      {
        main_height = 400
      }
      else
      {
        main_height = 400
      }
      actual_height = main_height * props.size / 170

    }

   
    else
    {
      if(props.switchoption == "Snowboard")
      {
        main_height = 600;
      }
      else
      {
        main_height = 600;
      }
      actual_height = main_height * props.size / 170

    }


    return (
        <div class="bar" style={{
            background: props.color,
            height: actual_height
          }}
          
          ><h2>{props.size}cm</h2></div>
       
    )
}


export default Masurment_unit;