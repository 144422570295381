import React, { useState, useEffect } from "react";
import loading from "../assest/loading.gif";
import loading_2 from "../assest/loading_graphics.png";
import good from "../assest/good.png";
import premium_boards_img from "../assest/premium_board.svg";

const Mask = ({
  mask,
  width,
  height,
  color,
  size,
  tail,
  tip,
  onClick1,
  tailtip,
  image,
  view,
  lineview,
}) => {
  const [tail_main, Settail] = useState();
  const [tip_main, Settip] = useState();

  const fakeCdpr = () => {
    let tailcm = tail / 10;
    let tipcm = tip / 10;
    let tailpx;
    let tippx;

    if (tailtip != null) {
      tailpx = (tailtip * tailcm) / size;
      tippx = (tailtip * tipcm) / size;

      Settail(tailpx);
      Settip(tippx);
    }

    // console.log(tailpx)
  };

  useEffect(() => {
    fakeCdpr();
  }, [height, tailtip]);

  // fakeCdpr()

  return (
    <div
      className="product-graphics-box"
      data-img-src={good}
      style={{
        width: width,
        height: size == 156 ? height - 7 : height,
      }}
    >
      <div
        className={
          view == "Graphics" && !lineview
            ? "product-graphics-ima"
            : "product-graphics-ima product-graphics-hidden"
        }
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "100% 100%",
        }}
      ></div>
      <div
        className="product-graphics-img"
        style={{
          WebkitMaskImage: mask,
          maskImage: mask,
          background: color,
        }}
        onClick={onClick1}
      ></div>
      <div
        className="bar"
        style={{
          background: color,
        }}
      >
        <h2>{size}</h2>
        <div className="dotted-blank"></div>
      </div>
      <div
        className="tail-bar"
        style={{
          width: width,
          background: color,
          marginBottom: -10,
        }}
      >
        <h2>{tail}</h2>
      </div>

      <div
        className="tip-bar"
        style={{
          width: width,
          background: color,
          marginTop: -10,
        }}
      >
        <h2>{tip}</h2>
      </div>
    </div>
  );
};

const Productgraphics = (props) => {
  const [deminition, setdeminition] = useState({});
  const [tailtip, settailtip] = useState({});
  let [image, setImage] = useState("null");
  let height_size = 190;

  let [imageActive, setActive] = useState(false);

  useEffect(() => {
    const img = new Image();
    const img2 = new Image();


    img.src = props.url;
    img2.src = props.url2;

    img.onload = function () {
      let properties;
      let main_width;
      let main_height;
      let props_tail;

      if (props.tail > 1000) {
        props_tail = props.tail / 10;
      } else {
        props_tail = props.tail;
      }

      if (!props.lineview) {
        if (window.innerWidth < 600) {
          if (props.switchoption == "Snowboard") {
            main_height = 250;
          } else {
            main_height = 250;
          }

          main_width = (main_height * 40) / height_size;
          properties = {
            width: (main_width * props_tail) / 400,
            height: (main_height * props.size) / 170,
          };
        } else if (props.canvasWidth < 1799) {
          if (props.switchoption == "Snowboard") {
            main_height = 400;
          } else {
            main_height = 400;
          }
          main_width = (main_height * 40) / height_size;
          properties = {
            width: (main_width * props_tail) / 400,
            height: (main_height * props.size) / 170,
          };
        } else {
          if (props.switchoption == "Snowboard") {
            main_height = 600;
          } else {
            main_height = 600;
          }
          main_width = (main_height * 40) / height_size;
          properties = {
            width: (main_width * props_tail) / 400,
            height: (main_height * props.size) / 170,
          };
        }

        // if(props.canvasHeight > 800)
        // {
        //   const ratio = this.height / this.width
        //   properties = {width : props.canvasHeight * 0.0875 , height : (props.canvasHeight * 0.0875) * ratio}
        // }

        // else
        // {
        //   const ratio = this.height / this.width
        //   properties = {width : 70 , height : 70 * ratio}

        //   console.log(props.canvasHeight)
        // }

        setdeminition(properties);
        setImage(img);
        setActive(true);

        console.log(properties, props_tail);
      }

      if (props.canvasWidth < 1799) {
        if (props.switchoption == "Snowboard") {
          main_height = props.canvasHeight / 1.35;
        } else {
          main_height = props.canvasHeight / 1.6;
        }

        main_width = (main_height * 40) / height_size;
        properties = {
          width: (main_width * props_tail) / 400,
          height: (main_height * props.size) / height_size,
        };
      } else if (window.innerWidth < 600) {
        main_height = 250;
        main_width = (main_height * 40) / height_size;
        properties = {
          width: (main_width * props_tail) / 400,
          height: (main_height * props.size) / height_size,
        };
      } else if (window.innerWidth >= 1800) {
        if (props.switchoption == "Snowboard") {
          main_height = 500;
        } else {
          main_height = 500;
        }
        main_width = (main_height * 40) / height_size;
        properties = {
          width: (main_width * props_tail) / 400,
          height: (main_height * props.size) / height_size,
        };
      }

      settailtip(properties);
    };

    img2.onload = function () {
      let properties;
      let main_width;
      let main_height;

      if (props.lineview) {
        if (window.innerWidth < 600) {
          if (props.switchoption == "Snowboard") {
            main_width = 250;
          } else {
            main_width = 250;
          }

          const actuallwidth = (main_width * props.size) / 170;
          const ratio = this.height / this.width;

          properties = { width: actuallwidth, height: ratio * actuallwidth };

          setdeminition(properties);
          setImage(img2);
          setActive(true);
        } else if (window.innerWidth < 1799) {
          if (props.switchoption == "Snowboard") {
            main_width = 400;
          } else {
            main_width = 400;
          }

          const actuallwidth = (main_width * props.size) / 170;
          const ratio = this.height / this.width;

          properties = { width: actuallwidth, height: ratio * actuallwidth };

          setdeminition(properties);
          setImage(img2);
          setActive(true);
        } else {
          if (props.switchoption == "Snowboard") {
            main_width = 600;
          } else {
            main_width = 600;
          }

          const actuallwidth = (main_width * props.size) / 170;
          const ratio = this.height / this.width;

          properties = { width: actuallwidth, height: ratio * actuallwidth };

          setdeminition(properties);
          setImage(img2);
          setActive(true);
        }
      }
    };

    // eslint-disable-next-line
  }, [props.canvasHeight, props.url, props.lineview]);

  // else
  // {
  //
  // }

  // const changeWidth = (imgWidth,imgHeight) => {
  //     var yourImg = document.getElementById('step1');
  //     if(yourImg && yourImg.style) {
  //         yourImg.style.height = imgHeight + 'px';
  //         yourImg.style.width = imgWidth + 'px';
  //     }

  return (
    <div
      className="snowboard-graphics-container"
      style={!props.lineview ? { height: deminition.height } : null}
    >
      <div className="graphics-img-container">
        <img
          src={premium_boards_img}
          className="premium-brand-img"
          alt="premium-brand"
        />
        {imageActive ? (
          <Mask
            size={props.size}
            mask={"url(" + image.src + ")"}
            width={deminition.width}
            height={deminition.height}
            tailtip={tailtip.height}
            color={props.color}
            tail={props.tail}
            tip={props.tip}
            onClick1={props.onClick1}
            image={props.url3}
            view={props.view}
            lineview={props.lineview}
          />
        ) : (
          <img
            src={window.innerWidth > 800 ? loading : loading_2}
            alt="loading.."
          />
        )}
      </div>
    </div>
  );
};

export default Productgraphics;


// dare to lead