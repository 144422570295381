import React from "react";

const Masurment_unit3 = (props) => {
  // const changeWidth = (imgWidth,imgHeight) => {
  //     var yourImg = document.getElementById('step1');
  //     if(yourImg && yourImg.style) {
  //         yourImg.style.height = imgHeight + 'px';
  //         yourImg.style.width = imgWidth + 'px';
  //     }

  let max_height;
  
  if(window.innerWidth < 1800) {
    max_height = 75;
  }
  else {
    max_height = 105;
  }
  let props_tail;
  if (props.tail > 1000) {
    props_tail = props.tail / 10;
  } else {
    props_tail = props.tail;
  }
  let actual_width = (max_height * props_tail) / 350;

  return (
    <div
      class="tail-bar"
      style={{
        background: props.color,
        width: actual_width,
      }}
    >
      <h2>{props_tail}</h2>
    </div>
  );
};

export default Masurment_unit3;
