
import React, { useState, useEffect, useRef } from "react"

const Graph = (props) => {

  const [bar] = useState(props.graph)
  const canvas = useRef(null)
  const [barActive , setbarActive] = useState(null)


function MouseOver(event) {
  let codmobile =  canvas.current.getBoundingClientRect()
  let y = event.clientY - codmobile.top
  let x = event.clientX - codmobile.left
  
  for(let i = 1; i <= 4; i++)
  {
    if(y < 95 && y > 5 && x < props.width - 20 && x > 5)
    {
          if(i === 1)
        {
          if(y <= i * 25 && barActive !== i)
          {
            setbarActive(i);
          }
        }

        else
        {
          if(y <= i * 25 && y >= (i-1) * 25 && barActive !== i)
          {
            setbarActive(i);
          }
        }
    }
  
    else
    {
      setbarActive(null);
    }
    
  }

  // console.log(barActive)
}


function drawRoundedBar(ctx, x, y, width, height, radius, color1) {

  if (!Number.isFinite(width) || width <= 0) {
    // console.error("Invalid width:", width);
    return;
  }

  const halfRadius = radius / 2;
 
  ctx.beginPath();
  ctx.moveTo(x + halfRadius, y);
  ctx.lineTo(x + width - halfRadius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + halfRadius);
  ctx.lineTo(x + width, y + height - halfRadius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - halfRadius, y + height);
  ctx.lineTo(x + halfRadius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - halfRadius);
  ctx.lineTo(x, y + halfRadius);
  ctx.quadraticCurveTo(x, y, x + halfRadius, y);
  ctx.closePath();

  ctx.fillStyle = color1;
  ctx.fill();
}


function getGradientColorStops(gradientString) {
  // Extract the color stops from the gradient string using regular expressions
  const colorStops = gradientString.match(/#[A-Fa-f0-9]{6}/g);
  return colorStops;
}


// function MouseOut() {
//   setbarActive(null)
// }


  useEffect(() => {

    if(canvas) {
          const ctx = canvas.current.getContext("2d")
          ctx.clearRect(0,0,props.width,bar.length * 20 + 40)
          let width_1;
          if(window.innerWidth < 950) {
            width_1 = 180
          }
          else {
            width_1 = props.width
          }
          for(let i = 0; i < bar.length; i++)
          {
            for(let j = 0; j < (width_1 / 4); j++)
            {
              // ctx.fillStyle = props.color
              // ctx.fillRect(j*4,(i*26 + 10),0.5,15)
            }

            ctx.fillStyle = "white"

            if(bar[i].precentage == 'undefined' ||  bar[i].precentage == "null" || isNaN(bar[i].precentage))
              {
                  //  ctx.fillRect(0,(i*26 + 10),props.width * 100 / 100,15);
                  if(window.innerWidth < 950) {
                    ctx.font = "500 11px Poppins"
                  }
                  else {
                    ctx.font = "500 14px Poppins"
                  }

                   ctx.fillText(bar[i].name,0, (i*26 + 10) + 14);

                   ctx.fillRect(45,(i*26 + 10) + 8,5,2);

                   drawRoundedBar(ctx,60, (i*26 + 10), width_1 * bar[i].precentage / 100, 20, 10,props.color);
                   
              }
              else
              {
                if(bar[i].precentage > 40) {
                  if(window.innerWidth < 950) {
                    ctx.font = "500 11px Poppins"
                  }
                  else {
                    ctx.font = "500 14px Poppins"
                  }

                  ctx.fillText(bar[i].name,0, (i*26 + 10) + 14);
                   ctx.fillRect(45,(i*26 + 10) + 8,5,2);
                  
                  drawRoundedBar(ctx,60, (i*26 + 10), width_1 * bar[i].precentage / 100 - 80, 20, 10,props.color);
                }
                else
                {
                   if(window.innerWidth < 950) {
                    ctx.font = "500 11px Poppins"
                  }
                  else {
                    ctx.font = "500 14px Poppins"
                  }
                  
                  ctx.fillText(bar[i].name,0, (i*26 + 10) + 14);
                  ctx.fillRect(45,(i*26 + 10) + 8,5,2);


                  drawRoundedBar(ctx,60, (i*26 + 10), width_1 * bar[i].precentage / 100, 20, 10,props.color);
                }
               
              }

              if(window.innerWidth < 950) {
                ctx.font = "500 11px Poppins"
              }
              else {
                ctx.font = "500 14px Poppins"
              }
              ctx.fillStyle = "white"
              ctx.textAlign = "left"

              if(bar[i].precentage == 'undefined' ||  bar[i].precentage == "null" || isNaN(bar[i].precentage))
              {
                ctx.fillText(bar[i].amount,width_1 * 100 / 100 + 30, (i+1) * 26 - 3);
              }
              else if(bar[i].precentage > 40) {
                ctx.fillText(parseInt(bar[i].amount)+""+bar[i].masure ,width_1 * bar[i].precentage / 100 - 80, (i+1) * 26 - 3);
              }
              else
              {
                ctx.fillText(parseInt(bar[i].amount)+""+bar[i].masure  ,width_1 * bar[i].precentage / 100 + 70, (i+1) * 26 - 3); 
              }

              if(bar[i].name == "Taper") {
                console.log(bar[i].precentage)
              }
            
          }

          ctx.fillStyle = "white"
          ctx.fillRect(50,10,1,bar.length * 20 + 20);


      
    }
    // eslint-disable-next-line
  }, [props.color , barActive , props.bar , props.lineview])

  return (
        <canvas className="graph-canvas"
          ref={canvas}
          width={window.innerWidth > 900 ? props.width : 150}
          height={bar.length * 20 + 40}
          onMouseMove={MouseOver}
        />
  )
}

export default Graph